<template>
  <div class="content-wrapper">
    <PageHeader :screenName="form.nome" :linkItems="linkItems" />

    <ErrorModal
      :modalShow="modalError.showModal"
      :typeModal="modalError.typeModal"
      :caseModal="modalError.caseModal"
      @confirmed="redirecionarLista"
    />

    <main class="card">
      <HeaderEmpresa :empresa="empresa" @confirmed="resolveRoute" />
      <section class="form p-2">
        <b-form>
          <b-row>
            <b-col lg="4" md="4" sm="12">
              <b-form-group label="CPF" label-class="font-weight-bold">
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.cpf | VMask("###.###.###-##") }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group
                label="Nome completo"
                label-class="font-weight-bold"
              >
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.nome }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group label="Nome da mãe" label-class="font-weight-bold">
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.nome_mae }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group label="Sexo" label-class="font-weight-bold">
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.genero ? form.genero : "-" }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group
                label="Data de nascimento"
                label-class="font-weight-bold"
              >
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ formatDate(form.data_nascimento) }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group
                label="Nacionalidade"
                label-class="font-weight-bold"
              >
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.descricao_nacionalidade }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group label="Matrícula" label-class="font-weight-bold">
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.matricula ? form.matricula : " - " }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group label="Setor" label-class="font-weight-bold">
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.setor ? form.setor : " - " }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group label="Categoria" label-class="font-weight-bold">
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.categoria.descricao }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </section>
      <section v-if="editable && canManageColab" class="buttons p-2">
        <b-button
          id="edit-aviso"
          @click.prevent="redirectToEdit()"
          variant="custom-blue"
        >
          <feather-icon icon="EditIcon" class="mr-50" />
          <span class="align-middle">Editar</span>
        </b-button>
      </section>
    </main>
  </div>
</template>

<script>
import { actions, subjects } from "@/libs/acl/rules";
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BButton,
  BFormTextarea,
  BLink,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import ErrorModal from "@/views/components/custom/modals/ErroIdInexistenteModal.vue";
import HeaderEmpresa from "@/views/components/custom/page-header/Header-empresa.vue";

export default {
  title: "Detalhes colaborador",
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BButton,
    PageHeader,
    VuePerfectScrollbar,
    ErrorModal,
    BLink,
    HeaderEmpresa,
  },

  data() {
    return {
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
      linkItems: [
        {
          name: "Empresas",
          routeName: "empresa-colaborador-list",
        },
        {
          name: "Carregando dados...",
          active: true,
        },
        {
          name: "Carregando dados...",
          active: true,
        },
      ],
      today: moment().format("YYYY-MM-DD"),
      editable: true,
      modalError: {
        showModal: false,
        typeModal: "error",
        caseModal: "idInexistente",
      },
      form: {
        cpf: "",
        nome: "",
        genero: "",
        data_nascimento: "",
        editavel: "",
        categoria: "",
        matricula: "",
        setor: "",
        nome_mae: "",
        id_nacionalidade: "",
        descricao_nacionalidade: "",
      },
      canManageColab: false,
      empresa: {
        id_pessoa: "",
        nome: "",
      },
    };
  },
  mounted() {
    if (this.$route.params.idPessoa) {
      this.preenchimentoFormulario();
    } else {
      return this.openModalError();
    }
    this.canManageColab = this.$can(
      actions.GERENCIAR,
      subjects.COLABORADOR_MENUS_ROTAS
    );
  },

  methods: {
    preenchimentoFormulario() {
      this.idPessoaShow = this.$route.params.idPessoa;
      this.form.cpf = this.$route.params.cpf;
      this.form.nome = this.$route.params.nome;
      this.form.genero = this.$route.params.genero;
      this.form.data_nascimento = this.$route.params.data_nascimento;
      this.form.nome_mae =
        this.$route.params.nome_mae != null ? this.$route.params.nome_mae : "-";
      this.form.id_nacionalidade = this.$route.params.id_nacionalidade;
      this.form.descricao_nacionalidade =
        this.$route.params.descricao_nacionalidade != null
          ? this.$route.params.descricao_nacionalidade
          : "-";
      this.form.categoria = this.$route.params.categoria;
      this.form.matricula = this.$route.params.matricula;
      this.form.setor = this.$route.params.setor;
      this.form.editavel = this.$route.params.editavel;
      if (this.$route.params.empresa) {
        this.empresa = this.$route.params.empresa;
        this.linkItems[1].name = this.empresa.nome;
      } else {
        this.redirectToList();
      }
      this.linkItems[2].name = this.form.nome;
    },
    redirecionarLista() {
      this.$router.push({ name: "empresa-colaborador-list" });
    },

    openModalError() {
      this.modalError.showModal = true;
    },
    redirectToEdit() {
      this.$router.push({
        name: "empresa-colaborador-edit",
        params: {
          idPessoa: this.$route.params.idPessoa,
          cpf: this.$route.params.cpf,
          nome: this.$route.params.nome,
          genero: this.$route.params.genero,
          data_nascimento: this.$route.params.data_nascimento,
          categoria: this.$route.params.categoria,
          empresa: this.$route.params.empresa,
          ano: this.$route.params.ano,
          editavel: this.$route.params.editavel,
          matricula: this.$route.params.matricula,
          setor: this.$route.params.setor,
          nome_mae: this.$route.params.nome_mae,
          id_nacionalidade: this.$route.params.id_nacionalidade,
          descricao_nacionalidade: this.$route.params.descricao_nacionalidade,
        },
      });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    resolveRoute(route) {
      if (route != this.$route.name) {
        this.$router.push({
          name: route,
          params: {
            empresa: this.empresa,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.altura_max_input_scrollbar {
  max-height: 32px;
  word-break: break-all;
  padding-right: 15px;
}
</style>